.root > div {
  animation: delay 1.4s infinite ease-in-out both;
}

.root .bounce1 {
  animation-delay: -0.32s;
}

.root .bounce2 {
  animation-delay: -0.16s;
}

@keyframes delay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
